import { CircularProgress } from '@mui/material';
import React from 'react';
import { StyledLoaderWrapper } from './StyledLoader';

export const Loader = () => {
  return (
    <StyledLoaderWrapper>
      <CircularProgress color='primary' />
    </StyledLoaderWrapper>
  );
};

export default Loader;
